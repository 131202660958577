import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import { Provider } from 'react-redux'; //Add to line 1
import store from './redux/store/store'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Login from './pages/Login';
import { createAnimation } from '@ionic/react';


setupIonicReact();

const animationBuilder = (baseEl : any, opts: any) => {

  const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo(
      'transform',
      'translateX(100%)',
  
      'translateX(0)'
    )
   // .direction('reverse')
  .duration(250);
  
  const leavingAnimation = createAnimation()
    .addElement(opts.leavingEl)
    .fromTo(
      'transform',
      'translateX(100%)',
  
      'translateX(0)'
    )
    .direction('reverse')
  .duration(350);
  
  const animation = createAnimation()
    .addAnimation(enteringAnimation)
    .addAnimation(leavingAnimation);
    
  return animation;
};

const App: React.FC = () =>  (
  <Provider store={store}> 
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet animation={animationBuilder}>
        <Route path="/" exact={true}>
          <Redirect to="/home" />
        </Route>
        <Route path="/home" exact={true}>
          <Home />
        </Route>
        <Route path="/login">
           <Login />
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
  </Provider>
)

export default App;
