import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  useIonRouter,
  useIonViewWillEnter,
  IonSelect, IonSelectOption,
  IonButton,
  IonCol,
  IonRow,
  useIonToast
} from '@ionic/react';
import './Home.css';
import { useDispatch, useSelector } from 'react-redux';
import { getMeDetails, getProfAffiliate, setAsAuthenticated } from '../redux/actions/authActions';
import { useLocation, useParams } from 'react-router';
import logo from '../assets/imgs/logo.svg'
import { APP_URL } from '../redux/actions/api';
import { IonAvatar, IonChip } from '@ionic/react';

const Home = (props) => {
  const router = useIonRouter()
  const { user, isAuthenticated } = useSelector((state = {}) => state.auth);
  const { liveActions } = useSelector((state = {}) => state.async)
  const dispatch = useDispatch()
  const [currentDate, setCurrentDate] = useState(null);
  const [present] = useIonToast();
  const search = useLocation().search

  useIonViewWillEnter(() => {

    let params = new URLSearchParams(search);
    if (params?.get('token')) {
      localStorage.setItem('AuthToken', params.get('token'));
      dispatch(setAsAuthenticated(localStorage.getItem('AuthToken')))
      dispatch(getMeDetails())
      router.push('/home', null, 'replace');
      return;
    }


    if (!isAuthenticated && localStorage.getItem("AuthToken") && !liveActions.includes('me')) {
      dispatch(setAsAuthenticated(localStorage.getItem('AuthToken')))
      dispatch(getMeDetails())
    }
  });



  useEffect(() => {
    if  (!isAuthenticated && !localStorage.getItem("AuthToken")) {
      router.push('/login')
    }

  }, [isAuthenticated])

  const DateChanged = (e) => {
    setCurrentDate(e.detail.value)
  }


  const currentData = user?.affiliate_data?.memberFinish && currentDate 
    ? user?.affiliate_data?.memberFinish.find(row => (row.month +'-'+row.year) == currentDate) 
    : null


  const refresh = (e) => {
    setTimeout(() => {
      e.detail.complete();
    }, 3000);
  };

  const ShareClick = () => {
    const url = user.short_aff_link ? user.short_aff_link : `${APP_URL}home/?source=${user.affiliate_url}`;
 
    navigator.clipboard.writeText(url);//`${APP_URL}home/?source=${user.affiliate_url}`);
    present( {
      'message' :'הלינק הועתק , \n הדבק אותו בWhatsApp או ברשת החברתית' ,
      'duration': 1500,
      //"position": 'middle',
    })
 //   window.open(`https://wa.me/?text=` + `דוקטוריטה -  נכנסים להמליץ ויוצאים לנופש ` + '%0a'  +`${APP_URL}home/?source=${user.affiliate_url}`,'_blank')
  }


  useEffect(() => {
    if(!currentDate && user?.affiliate_data?.memberFinish) {
      let date = user?.affiliate_data?.memberFinish.map(m => { return {year : m.year, month: m.month}});
      let mergeMonths = [...currentMonths, ...date];
      var distinct = []
      for (var i = 0; i < mergeMonths.length; i++){
        if (!distinct.find(m => m.month == mergeMonths[i].month && m.year == mergeMonths[i].year)){
            distinct.push(mergeMonths[i])
        }
      }
      distinct = distinct.sort((a, b) => {
        return (new Date(a.year, a.month) < (new Date(b.year, b.month)))        
      })

      setCurrentMonths(distinct)          
      setCurrentDate( ((new Date).getMonth() + 1)+'-' + (new Date).getFullYear())
    }

  },[user?.affiliate_data?.memberFinish])

  
  const getMonths = () => {
    const startDate = new Date('2023-08-01');
    const fromDate = new Date()
    fromDate.setMonth(fromDate.getMonth() - 4);
    if(fromDate < startDate) {
      fromDate.setMonth(7);
      fromDate.setFullYear(2023)
    }
    
    
    const toDate = new Date()
    toDate.setMonth(toDate.getMonth() + 4);
    const fromYear = fromDate.getFullYear();
    const fromMonth = fromDate.getMonth();
    const toYear = toDate.getFullYear();
    const toMonth = toDate.getMonth();
    const months = [];
    for(let year = fromYear; year <= toYear; year++) {
        let monthNum = year === fromYear ? fromMonth : 0;
        const monthLimit = year === toYear ? toMonth : 11;

        for(; monthNum <= monthLimit; monthNum++) {
            let month = monthNum + 1;
            months.push({ year, month });
        }
    }
    return months;
}

  const [currentMonths, setCurrentMonths] = useState(getMonths())
  const months = currentMonths//  user?.affiliate_data?.memberFinish;

  return (
    <IonPage id="home-page">
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <img src={logo} className='logo' alt='logo' />
        
        <IonAvatar className='avatar-elem'>
          <span className="user-avatar">{user?.first_name?.charAt(0)}{user?.last_name?.charAt(0)}</span>
        </IonAvatar>
        <h4 style={{marginTop: '-5px'}}>
          סיכום הרשמות ורווח
        </h4>
        <table className='paymentTable'>
            <thead>
              <th>הרשמות</th>
              <th>סכום</th>
            </thead>
            <tbody>
            <tr>
              <td><b>{user?.affiliate_data?.memberFinish?.reduce((total, elem) => {
                return total + elem.memberFinish;
              }, 0)}</b></td>
              <td><b>{(user?.affiliate_data?.memberFinish?.reduce((total, elem) => {
                return total + elem.memberFinish;
              }, 0) * parseFloat(user?.affiliate_data?.price_for_member)).toFixed(0)} ₪</b></td>
           
            </tr>
            </tbody>
        </table>

        <div>
          <h4>
            פירוט לפי חודשים
          </h4>
        <IonSelect value={currentDate} okText='בחר' cancelText='סגור' label='חודש' interface="action-sheet"
         className='tel-input'  placeholder="חודש" onIonChange={DateChanged}>
        {months?.map((row, i) => (
          <IonSelectOption  key={i} value={row.month+'-'+row.year}>{row.month+'/'+row.year}</IonSelectOption>
        ))}
        </IonSelect>  <br/>

        {currentData ? <>
        <div className=''>
          <table className='paymentTable'>
            <thead>
              <th>הרשמות</th>
              <th>סכום</th>
            </thead>
            <tbody>
            <tr>
              <td><b>{currentData.memberFinish}</b></td>
              <td><b>{(parseInt(currentData.memberFinish) * parseFloat(user?.affiliate_data?.price_for_member)).toFixed(0)} ₪</b></td>
           
            </tr>
            </tbody>
        </table>
        </div>
        <hr/>
          <div className='members-list'>
            <table>
              <tbody>
              
            {currentData.members?.map((m, i) => (
                <tr key={m.id}>
                  <td><p>{i + 1}. {m.full_name}</p></td> 
                  <td><p>{m.created_at}</p></td>
                </tr>
            ))}
                 </tbody>
            </table>
         
            </div>
            
        </> : <p>לא קיימות הרשמות</p>}
        

        </div> 

         <div className='sticky'>    
        <IonRow>
          <IonCol>
            <p className='tnxTitle'>
           תודה על העזרה בהגדלת מאגר ההמלצות 
           <br/>
           לרופאים .ות ומטפלים .ות
            </p>
            <h4>
         לשיתוף חברים וחברות בWhatsApp וברשתות החברתיות יש להעתיק את הקישור הבא ולשתף
            </h4>
          </IonCol>
        </IonRow>

        <IonRow className='next-btn-row gift-row'>
          <IonCol>
            {!!user?.affiliate_url &&
               <IonButton className='next-btn send-btn' shape="round" onClick={ShareClick} >
                 שיתוף עם קוד אישי
              </IonButton>
            }
          </IonCol>
        </IonRow>
        </div>
        
        
      </IonContent>
    </IonPage>
  );
};

export default Home;
