import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  IonContent,
  IonInput,
  IonPage,
  IonButton,
  IonCol,
  IonRow,
  useIonRouter,
  IonCheckbox,
  IonLoading,
  IonModal,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonIcon
} from '@ionic/react';
import './Login.css';
import { useDispatch, useSelector } from 'react-redux';
import { login, restartOtp, sendOtp } from '../redux/actions/authActions';
import logo from '../assets/imgs/logo.svg'
import { closeOutline } from 'ionicons/icons';
import { useLocation } from 'react-router';
import ReCAPTCHA from "react-google-recaptcha";


const Login = () => {
  const router = useIonRouter()
  const location = useLocation()
  const { user, isAuthenticated, otp, prof_affiliate, settings } = useSelector((state = {}) => state.auth);
  const { loading } = useSelector((state = {}) => state.async);
 
  const [mobile, setMobile] = useState('');
  const [code, setCode] = useState(null);
  const [is_policy, setIsPolicy] = useState(false);
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false);
  const [isPOpen, setIsPOpen] = useState(false)

  const recaptchaRef = React.useRef();

  useEffect(() => {
    if(location.pathname == '/login') {
      if(isAuthenticated) {
          router.push('/home')
      } 
    }
  }, [isAuthenticated])

  useEffect(() => {
    try {
      if ("OTPCredential" in window) {
        const ac = new AbortController();
        ac.abort();

        navigator.credentials
          .get({
            otp: { transport: ["sms"] },
            signal: ac.signal,
          })
          .then((otp) => {
            console.log(otp)
            setCode(otp.code);
            handleSubmit()
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (e) {
      console.log(e)
    }
  }, []);


  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!otp || otp == 'fresh') {
        recaptchaRef.current.executeAsync().then(dtoken => {
      if (!mobile) {
        return;
      }
      dispatch(sendOtp({
        mobile: mobile,
        is_affiliate: true,
        recaptcha: dtoken, 
      }));
      })
    } else {
      if (isAuthenticated) {
        router.push('/name')
        return
      }
      dispatch(login({ mobile: mobile, verification_code: code }));
    }
  };

  const reSendCode = () => {
    dispatch(restartOtp())
  }


  const handleKeyUp = (event) => {
    if (event.key === 'Enter' && ((otp && otp != 'fresh') ? (is_policy && code && code.length >= 4) : (mobile && mobile.length == 10) )) {
      handleSubmit()
    }
  }

  return (
    <IonPage id="login-page">
      <IonContent fullscreen>
        <img src={logo} className='logo' alt='logo' />

        <IonRow>
          {!otp || otp === 'fresh' ?
            <IonCol>
              <p className='connexionTitle'>
                כניסת שותפים
              </p>
              <p className='login-desc'>שלחו לי קוד כניסה חד-פעמי למספר טלפון שלי</p>
            </IonCol>
            :
            <IonCol>
              <p className='connexionTitle sms-text'>
                מה הקוד שקיבלת בסמס?
              </p>
          <br />
              <span>לא קיבלת את הקוד?</span>
              <a className='resend-btn' onClick={reSendCode}> שלח/י שוב</a>
          
            </IonCol>
          }
        </IonRow>

        <IonRow>
          <IonCol>
            {!otp || otp === 'fresh' ?
            <>
              <IonInput className='tel-input' type="tel" value={mobile} onIonInput={(event) => setMobile(event.target.value)}
                inputmode="tel" required maxlength="10" pattern="[0][0-9]" placeholder='טלפון'
                onKeyUp={handleKeyUp}>
              </IonInput>
              <ReCAPTCHA
                  size="invisible"
                    ref={recaptchaRef}
                    className="captcha-st"
                    sitekey={process.env.CAPTCHA_SITE_KEY || '6LcrBX0nAAAAAIFi7kTukrVV0zFZAdtxK56x010M'} 
                  />
              </>
              : <IonInput className='sms-input' type="tel" value={code} onIonInput={(event) => setCode(event.target.value)}
                inputmode="tel" required maxlength="6" pattern="[0-9]"
                onKeyUp={handleKeyUp}>
              </IonInput>
            }
          </IonCol>
        </IonRow>
        {otp && otp !== 'fresh' && <div className='checkboxes' style={{ maxWidth: '80%', margin: '0 auto' }}>
          <IonRow>
          <IonCol size="2"></IonCol>
            <IonCol size="1" className='confirmMail-cbx'>
              <IonCheckbox labelPlacement="end" onIonChange={() => setIsPolicy(!is_policy)} checked={is_policy}></IonCheckbox>
            </IonCol>
            <IonCol className='policy-text'>
            <span onClick={() => setIsPolicy(!is_policy)}>קראתי ואני מסכימ.ה</span>
            <a onClick={() => setIsPOpen(true)}> למדיניות הפרטיות </a>       
            <a onClick={() => setIsOpen(true)}> ולתנאי השימוש </a>
            <span onClick={() => setIsPolicy(!is_policy)}>
            באתר .
          </span>   
         
            </IonCol>
          </IonRow>
        </div>}

        <IonRow>
          <IonCol>
            {((otp && otp != 'fresh') ? (is_policy && code && code.length >= 4) : (mobile && mobile.length == 10))  &&
            <IonButton disabled={loading}  onClick={handleSubmit} className='next-btn send-btn' shape="round" style={{ width: '80%' }}>
              { (otp && otp != 'fresh') ? 'שליחה' : 'אישור'}


            </IonButton>}
            {loading &&
            <IonLoading isOpen={loading} />}

          </IonCol>
        </IonRow>

        {otp == 'fresh' && 
        <IonRow>
          <IonCol>
          <p style={{color: 'red'}}>הפרטים שהוזנו אינם נכונים </p>   
          </IonCol>
        </IonRow>}

        <IonModal isOpen={isOpen}>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsOpen(false)}>    <IonIcon icon={closeOutline} ></IonIcon>
                    </IonButton>
              </IonButtons>
            </IonToolbar>
          <IonContent className="ion-padding">
          <h3 style={{textAlign: 'center'}}>{settings?.find(s => s.Slug == 'terms-of-use')?.Name}</h3>

          <div style={{textAlign: 'justify', padding: '10px', fontFamily: 'auto'}}
           dangerouslySetInnerHTML={{ __html: settings?.find(s => s.Slug == 'terms-of-use')?.Value }}></div>
                       
          </IonContent>
        </IonModal>

        <IonModal isOpen={isPOpen}>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsPOpen(false)}>    <IonIcon icon={closeOutline} ></IonIcon>
                    </IonButton>
              </IonButtons>
            </IonToolbar>
          <IonContent className="ion-padding">
          <h3 style={{textAlign: 'center'}}>{settings?.find(s => s.Slug == 'privacy')?.Name}</h3>

          <div style={{textAlign: 'justify', padding: '10px', fontFamily: 'auto'}}
          dangerouslySetInnerHTML={{ __html: settings?.find(s => s.Slug == 'privacy')?.Value }}></div>
                       
          </IonContent>
        </IonModal>

      </IonContent>
    </IonPage>
  );
};

export default Login;
